<template>
  <div class="main-customer-reviews">
    <div class="grid">
      <div
        v-for="customer in CUSTOMER_REVIEWS"
        class="grid__cell col-1/3--lap-and-up"
      >
        <div class="main-customer-reviews__item">
          <div
            v-html="customer.title"
            class="main-customer-reviews__title"
          ></div>
          <div class="main-customer-reviews__photo">
            <img
              :src="
                `/images/main/customer_reviews/${customer.customer_name}_대표.jpeg`
              "
              :alt="customer.customer_name"
            />
          </div>
          <div class="main-customer-reviews__company">
            {{ customer.company_name }}
          </div>
          <div class="main-customer-reviews__boss">
            {{ customer.customer_name }} 대표님
          </div>
          <div class="main-customer-reviews__btn">
            <button
              @click="onVideo(customer.youtube_code)"
              class="btn btn--small js-modal-video"
            >
              <span class="btn__icon">
                <img
                  src="~assets/images/customer-review/ico-youtube-blue.png"
                  alt=""
                />
              </span>
              인터뷰 영상 보기 ({{ customer.video_time }})
            </button>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <video-modal :id="id" />
    </client-only>
  </div>
</template>

<script>
import ModalVideoMixin from '~/components/mixins/modal-video.js'

export default {
  mixins: [ModalVideoMixin],
  data() {
    return {
      CUSTOMER_REVIEWS: [
        {
          title: '압도적으로 자문의 퀄리티가 높았어요.”',
          company_name: '(주)집사',
          customer_name: '이승권',
          youtube_code: 'lu780_GRoYA',
          video_time: '3:32'
        },
        {
          title: '혜움 시스템은 일종의 계몽이었죠.”',
          company_name: '(주)스튜디오 봄봄',
          customer_name: '이선용',
          youtube_code: 'w7thcPKiIEg',
          video_time: '4:07'
        },
        {
          title: '혜움은 스타트업을 가장 잘 아는 세무법인입니다.”',
          company_name: '(주)이콰리',
          customer_name: '정근택',
          youtube_code: '2g0JV_NzEhs',
          video_time: '4:40'
        },
        {
          title: '테크팀이 별도로 있다는 게 신뢰가 갔어요.”',
          company_name: '(주)넷스트림',
          customer_name: '상현태',
          youtube_code: 'igIBau7HjIQ',
          video_time: '2:29'
        },
        {
          title: '정부지원사업에 대한 경험이 풍부해 편합니다.”',
          company_name: '(주)스위브',
          customer_name: '최형석',
          youtube_code: 'M_lAZ8Regjw',
          video_time: '4:41'
        },
        {
          title: '혜움이 아니었으면<br /> 몰랐을거예요.”',
          company_name: '(주)랭코드',
          customer_name: '김민준',
          youtube_code: '7WnR7qr9kkE',
          video_time: '4:10'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.main-customer-reviews {
  padding: 4rem 1rem 3rem !important;
  @include clearfix;

  @include av-mq(desk) {
    padding: 96px 1rem 80px !important;
  }

  &__item {
    max-width: 280px;
    margin: 0 auto 2rem;
    @include clearfix;

    @include av-mq(handheld-and-up) {
      max-width: 350px;
    }

    @include av-mq(desk) {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 1.55;
    color: #323438;
    word-break: keep-all;

    &:before {
      content: '“';
      display: block;
      height: 40px;
      font-size: 40px;
      font-weight: bold;
      line-height: 1.48;
      color: #1f75e8;
    }

    @include av-mq(desk) {
      max-width: 250px;
      margin-bottom: 2rem;
      font-size: 20px;
    }
  }

  &__photo {
    float: left;
    margin-right: 1rem;

    img {
      max-width: 70px;
    }
  }

  &__company,
  &__boss {
    margin-bottom: 0.5rem;
    font-size: 16px;
  }

  &__btn {
    .btn {
      font-size: 14px;
      border: none;
      color: #1f75e8;
      font-weight: bold;
      padding: 0;
    }
  }
}
</style>
