<template>
  <div class="container">
    <main-hero />
    <content-section>
      <main-branch />
    </content-section>
    <content-section>
      <main-summary />
    </content-section>
    <content-section>
      <main-customer-reviews />
    </content-section>
    <content-section bg-color="#fafafa">
      <main-heum-system />
    </content-section>
    <content-section>
      <main-tax-avoidance />
    </content-section>
    <content-section :set-container="false" bg-color="#fafafa">
      <main-tax-accountant />
    </content-section>
    <content-section>
      <main-customer />
    </content-section>
    <content-section bg-color="#fafafa">
      <main-press />
    </content-section>
    <content-section :set-container="false" bg-color="#ecf2fe">
      <main-pricing />
    </content-section>
    <event-content
      :is-modal="true"
      :is-borderless="false"
      end-date="2025-01-01 00:00:00"
    >
      <a
        href="https://www.heum.report/?utm_source=heum&utm_medium=web&utm_campaign=alfred_traffic_ua_heum-main-popup"
        target="_blank"
        style="display: block; max-width: 480px;"
      >
        <img
          src="/images/modal/popup-20230915-2.png"
          alt="세무서비스 무료 오픈 대표님을 위한 기능 무료로 사용해 보세요! 지금 보러가기"
          style="max-width: 100%"
        />
      </a>
    </event-content>
  </div>
</template>

<script>
import EventContent from '@/components/common/event-content'
import MainBranch from '@/components/main/main-branch.vue'
import ContentSection from '~/components/common/content-section'
import MainHero from '~/components/main/main-hero'
import MainSummary from '~/components/main/main-summary'
import MainCustomerReviews from '~/components/main/main-customer-reviews'
import MainHeumSystem from '~/components/main/main-heum-system'
import MainTaxAvoidance from '~/components/main/main-tax-avoidance'
import MainTaxAccountant from '~/components/main/main-tax-accountant'
import MainCustomer from '~/components/main/main-customer'
import MainPress from '~/components/main/main-press'

import MainPricing from '~/components/main/main-pricing'

export default {
  components: {
    MainBranch,
    EventContent,
    ContentSection,
    MainHero,
    MainSummary,
    MainCustomerReviews,
    MainHeumSystem,
    MainTaxAvoidance,
    MainTaxAccountant,
    MainCustomer,
    MainPress,
    MainPricing
  }
}
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  max-width: 472px;
  height: 60px;
  margin-top: -14px;
  background-color: #fff;
  border-radius: 45px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  border: 0;
  color: #4031c5;

  animation-duration: 1s;
  animation-name: slidein;
  animation-timing-function: ease;
  animation-iteration-count: infinite;

  @include av-mq(lap-and-up) {
    max-width: 472px;
    height: 90px;
    font-size: 36px;
    line-height: 45px;
  }
}

@-webkit-keyframes slidein {
  0% {
    background-color: #fff;
    color: #4031c5;
  }
  50% {
    background-color: #ff3c64;
    color: #fff;
  }
}
</style>
