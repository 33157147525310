<template>
  <div class="main-press">
    <div class="main-press__title">언론에서 본 혜움</div>
    <div class="main-press__contents grid">
      <div
        v-for="press in pressList"
        class="main-press__item grid__cell col-1/4--lap-and-up"
      >
        <a
          :href="press.href"
          :target="press.target ? press.target : '_blank'"
          class="main-press__card"
        >
          <div class="main-press__card-content">
            <div class="main-press__card-logo">
              <img
                :src="`/images/main/logo-${press.name}.png`"
                :alt="`${press.name} 로고`"
              />
            </div>
            <div class="main-press__card-title">
              {{ press.title }}
            </div>
            <div class="main-press__card-date">
              {{ press.date }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pressList: [
        {
          name: 'joongang',
          title: '더낸세금, 2022 소비자의 선택 대상 수상',
          date: '2022.10.20',
          href:
            'https://www.heumtax.com/blog/%EC%84%B8%EA%B8%88%ED%99%98%EA%B8%89_%EC%84%9C%EB%B9%84%EC%8A%A4_%EB%8D%94%EB%82%B8%EC%84%B8%EA%B8%88_2022_%EC%86%8C%EB%B9%84%EC%9E%90%EC%9D%98%EC%84%A0%ED%83%9D_%EB%8C%80%EC%83%81%EC%88%98%EC%83%81',
          target: '_self'
        },
        {
          name: 'hankyung',
          title:
            '경정청구, 더 낸 세금 돌려받는 정당한 방법 [혜움의 택스 인사이트]',
          date: '2022.10.18',
          href: 'https://www.hankyung.com/thepen/moneyist/article/202210084843Q'
        },
        {
          name: 'etnews',
          title: "세무법인 혜움, '더낸세금' 가입자 2만명 돌파",
          date: '2022.10.05',
          href: 'https://www.etnews.com/20221005000009'
        },
        {
          name: 'sedaily',
          title:
            '[시그널] “더 낸 세금 찾아드려요” 세무서비스 ‘혜움랩스’ 첫 VC 투자 유치',
          date: '2022.02.25',
          href: 'https://www.sedaily.com/NewsView/262A1RDLCX'
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.main-press {
  max-width: 320px;
  margin: 0 auto;
  padding: 4.5rem 1rem 3rem;

  @include av-mq(lap-and-up) {
    max-width: 100%;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;

    @include av-mq(lap-and-up) {
      margin-bottom: 4rem;
      font-size: 32px;
    }
  }

  &__contents {
    position: relative;
    max-width: 300px;
    left: -0.5rem;
    margin: 0 auto !important;

    @include av-mq(handheld-and-up) {
      max-width: 600px;
    }

    @include av-mq(lap-and-up) {
      max-width: 100%;
    }
  }

  &__item {
    margin-bottom: 1rem !important;
  }

  &__card {
    display: block;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    overflow: hidden;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &-content {
      padding: 1rem;
    }

    &-photo {
      display: none;

      img {
        width: 100%;
      }
    }

    &-title {
      margin-bottom: 1rem;
      font-size: 15px;
      line-height: 1.5;
      color: #323438;

      @include av-mq(lap-and-up) {
        height: 68px;
      }
    }

    &-date {
      font-size: 16px;
      line-height: 1.5;
      color: #323438;
      text-align: right;
    }

    &-logo {
      text-align: left;
      margin-bottom: 0.5rem;

      img {
        max-width: 80px;
      }
    }
  }
}
</style>
