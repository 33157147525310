<template>
  <div
    v-if="showContent"
    :class="{ 'modal--borderless': isBorderless }"
    class="modal"
  >
    <template v-if="isModal">
      <transition name="modal">
        <div @click="closeContent" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button @click="setCookie" class="btn-set-cookie">
                    오늘 하루 그만보기
                  </button>
                  <button @click="closeContent" class="btn-close">
                    <template v-if="isBorderless">
                      <img src="/images/modal/btn-modal-close.png" alt="" />
                    </template>
                    <template v-else>닫기</template>
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import urlParse from 'url-parse'
import Cookies from 'js-cookie'

export default {
  name: 'EventContent',
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isBorderless: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String,
      default: undefined
    },
    endDate: {
      type: String,
      default: undefined
    },
    allowUtm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showContent: false
    }
  },
  mounted() {
    this.checkIsModal()
  },
  methods: {
    checkIsModal() {
      if (this.isModal) {
        this.showContent =
          this.checkCookie() && this.checkDate() && this.checkAllowUtm()
      } else {
        this.showContent = this.checkDate() && this.checkAllowUtm()
      }
    },
    checkAllowUtm() {
      if (this.allowUtm) {
        return true
      } else {
        const url = urlParse(window.location.href)
        return !url.query.includes('utm_')
      }
    },
    setCookie() {
      const END_OF_DAY = this.$moment()
        .endOf('day')
        .toDate()

      Cookies.set('close-event-modal', true, {
        expires: END_OF_DAY
      })

      this.closeContent()
    },
    closeContent() {
      this.showContent = false
    },
    checkCookie() {
      if (Cookies.get('close-event-modal') === 'true') {
        return false
      }

      return true
    },
    checkDate() {
      const CURRENT_DATE = this.$moment()
      const START_DATE = this.$moment(this.startDate)
      const END_DATE = this.$moment(this.endDate)

      if (this.startDate && CURRENT_DATE.diff(START_DATE) < 0) {
        return false
      }

      if (this.endDate && CURRENT_DATE.diff(END_DATE) >= 0) {
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.modal-container {
  display: inline-block;
  min-width: 320px;
  max-width: 600px;
  margin: 0 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.modal-body {
  img {
    max-width: 100%;
  }
}

.modal-footer {
  text-align: initial;
  padding: 0.5rem;
  background-color: #fff;

  button {
    padding: 0.5rem 0.75rem;
    background: #fff;
    font-size: 14px;
    border: 0;
  }

  .btn-close {
    float: right;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal--borderless {
  .modal-container {
    position: relative;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0;

    @include av-mq(lap-and-up) {
      padding: 2rem;
    }
  }

  .modal-footer {
    background: none;
    text-align: center;

    button {
      background: none;
      color: #fff;
      font-size: 20px;
    }

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
    }
  }
}
</style>
