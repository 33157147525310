<template>
  <div class="main-branch">
    <div class="main-branch__title">
      혜움을 함께 만들어가는<br />
      파트너 지점을 소개합니다.
    </div>
    <div class="main-branch__caroucel">
      <client-only>
        <carousel
          v-if="branch"
          :responsive="{
            0: { items: 2 },
            768: { items: 5 }
          }"
          :stagePadding="isMobile ? 46 : 64"
          :nav="false"
          :dots="false"
          :margin="isMobile ? 16 : 26"
        >
          <template slot="prev"><span class="owl-prev"></span></template>
          <main-branch-card
            v-for="(_branch, index) in branch"
            :key="index"
            :data="_branch"
          />
          <template slot="next"><span class="owl-next"></span></template>
        </carousel>
      </client-only>
    </div>
    <div class="main-branch__link">
      <nuxt-link to="/branch">전체지점 한눈에 보기 &#x2192;</nuxt-link>
    </div>
  </div>
</template>

<script>
import MainBranchCard from '@/components/main/main-branch-card.vue'
import { createClient } from '~/plugins/contentful'
const contentfulClient = createClient()

export default {
  components: {
    MainBranchCard
  },
  data() {
    return {
      branch: null
    }
  },
  computed: {
    isMobile() {
      const mq = this.$mq
      return mq === 'thumb' || mq === 'pocket'
    }
  },
  async fetch() {
    const [branch] = await Promise.all([
      contentfulClient.getEntries({
        content_type: 'heum-branch',
        order: 'fields.weight'
      })
    ])
    this.branch = branch.items.sort(() => Math.random() - 0.5)
  }
}
</script>

<style lang="scss">
.main-branch {
  padding: 4.5rem 0 3rem;
  overflow: hidden;

  @include av-mq(lap-and-up) {
    padding: 6.5rem 0 3rem;
  }

  &__title {
    padding: 0 1rem;
    margin-bottom: 4.5rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      font-size: 32px;
    }
  }

  &__company-logos {
    padding: 0 1rem;
  }

  &__caroucel {
    position: relative;
    margin-bottom: 2rem;
  }

  &__link {
    text-align: center;

    a {
      font-size: 18px;
      color: #1f75e8;
      text-decoration: none;

      @include av-mq(lap-and-up) {
        font-size: 22px;
      }
    }
  }

  .owl-stage-outer {
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 32px;
      right: 0;
      width: 2rem;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .owl-stage {
    padding: 16px 32px 16px 16px !important;
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 76px;
    height: 76px;
    cursor: pointer;
    background-size: cover;

    @include av-mq(lap-and-up) {
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
      height: 100px;
    }
  }

  .owl-prev {
    left: 1rem;
    background-image: url('~assets/images/main/btn-prev.png');
  }

  .owl-next {
    right: 1rem;
    background-image: url('~assets/images/main/btn-next.png');
  }
}
</style>
