<template>
  <div class="main-heum-system">
    <div class="main-heum-system__title">
      기술이 다른 혜움,<br />
      2만 고객의 피드백으로 만든 혜움의 시스템은 다릅니다.
    </div>
    <div class="main-heum-system__photo">
      <img
        src="~/assets/images/main/main-heum-system.jpg"
        srcset="
          @/assets/images/main/main-heum-system@2x.jpg 2x,
          @/assets/images/main/main-heum-system@3x.jpg 3x
        "
        alt=""
      />
    </div>
    <div class="main-heum-system__contents grid">
      <div class="grid__cell col-1/3--lap-and-up">
        <div class="main-heum-system__item">
          <div class="main-heum-system__item-title">
            <strong>1.</strong>일/월 보고서
          </div>
          <p class="main-heum-system__item-text">
            매달 매출, 손익, 비용 추이등을 전담 세무팀이 직접 정리하여 카톡으로
            보고해드립니다.
          </p>
        </div>
      </div>
      <div class="grid__cell col-1/3--lap-and-up">
        <div class="main-heum-system__item">
          <div class="main-heum-system__item-title">
            <strong>2.</strong>급여관리
          </div>
          <p class="main-heum-system__item-text">
            상세급여명세서를 작성하고 일괄발송할 수 있습니다.
          </p>
        </div>
      </div>
      <div class="grid__cell col-1/3--lap-and-up">
        <div class="main-heum-system__item">
          <div class="main-heum-system__item-title">
            <strong>3.</strong>카톡 응대 및 알림 시스템
          </div>
          <p class="main-heum-system__item-text">
            카카오톡 및 전화로 전문 세무사, 전담 세무팀이 정확한 상담을
            도와드립니다.
          </p>
        </div>
      </div>
    </div>
    <div class="main-heum-system__link">
      <nuxt-link to="/heum-system"
        >왜 국내 최고인지 알아보세요 &#x2192;</nuxt-link
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.main-heum-system {
  padding: 4.5rem 1rem 3rem;

  @include av-mq(lap-and-up) {
    padding: 6.5rem 1rem 3rem;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      font-size: 32px;
    }
  }

  &__photo {
    margin-bottom: 48px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  &__item {
    max-width: 250px;
    margin: 0 auto 2.5rem;

    @include av-mq(lap-and-up) {
      margin-bottom: 0;
    }
  }

  &__item-title {
    margin-bottom: 1rem;
    font-size: 22px;
    color: #323438;

    @include av-mq(lap-and-up) {
      font-size: 24px;
    }

    strong {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 32px;
      line-height: 1.48;
      color: #1f75e8;
    }
  }

  &__item-text {
    max-width: 250px;
    font-size: 16px;
    line-height: 1.48;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      font-size: 18px;
      line-height: 1.61;
    }
  }

  &__link {
    padding-top: 56px;
    text-align: center;

    @include av-mq(lap-and-up) {
      padding-top: 104px;
    }

    a {
      font-size: 18px;
      color: #1f75e8;
      text-decoration: none;

      @include av-mq(lap-and-up) {
        font-size: 22px;
      }
    }
  }
}
</style>
