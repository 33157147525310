<template>
  <div class="main-hero">
    <client-only>
      <template v-show="partnerContent.name && isLoaded">
        <div class="main-hero__container">
          <div
            :class="partnerContent.name && 'main-hero__text--partner'"
            class="main-hero__text"
          >
            <div v-if="partnerContent.name" class="main-hero__partner-sub-logo">
              <img :src="displayPartnerContent('', 'logo')" />
              <img
                src="~assets/images/main/partners/logo-x.png"
                class="logo-x"
              />
              <span>세무법인혜움</span>
            </div>

            <div v-if="partnerContent.name" class="main-hero__partner-logo">
              <img :src="displayPartnerContent('', 'logo')" />
              <span>세무솔루션</span>
            </div>

            <div v-if="!partnerContent.name" class="main-hero__sub-title">
              어렵지 않게, 기다리지 않게.
            </div>

            <div
              v-html="
                displayPartnerContent(
                  '일상처럼 편리한<br> 세무서비스, 혜움.',
                  'catchphrase'
                )
              "
              class="main-hero__title"
            ></div>

            <div class="main-hero__btns">
              <btn-consultation custom-class="btn btn--primary btn--large" />
            </div>
          </div>
        </div>
        <div class="main-hero__video">
          <video :key="videoSrc" muted autoplay playsinline>
            <source :src="videoSrc" type="video/mp4" />
          </video>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BtnConsultation from '@/components/common/btn-consultation.vue'

export default {
  components: { BtnConsultation },
  data: () => ({
    isLoaded: false
  }),
  computed: {
    ...mapGetters({
      partnerContent: 'getPartnerContent'
    }),
    videoSrc() {
      const s3 = 'https://heum-test.s3.amazonaws.com/landing_page_attachments/'
      const viewport = this.$mq === 'desk' ? 'desktop' : 'mobile'
      const videoTitle =
        this.partnerContent.name === 'fastfive'
          ? 'hero-video-fastfive'
          : 'hero-video-heumtax'
      return `${s3}${videoTitle}-${viewport}.mp4`
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true
    }, 0)
  }
}
</script>

<style lang="scss">
.main-hero {
  position: relative;
  height: 656px;
  overflow: hidden;

  @include av-mq(desk) {
    height: 800px;
    background: #f9f9fb;
  }

  &__container {
    position: relative;
    max-width: 1024px;
    height: 100%;
    margin: 0 auto;
  }

  &__text {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 124px;
    text-align: center;
    z-index: 2;
    box-sizing: border-box;

    @include av-mq(desk) {
      top: calc(50% + 40px);
      transform: translateY(-50%);
      left: 1rem;
      width: auto;
      height: auto;
      padding: 0;
      text-align: left;
    }
  }

  &__text--partner {
    padding-top: 100px;

    .main-hero__title {
      margin-top: -8px;

      @media screen and (max-width: 376px) {
        font-size: 26px;
      }
    }

    .main-hero__partner {
      @media screen and (min-width: 375px) {
        padding: 0 2rem;
      }
    }
  }

  &__partner-logo {
    margin-bottom: 1.5rem;
    padding: 0;
    text-align: center;

    @include av-mq(desk) {
      padding: 0 0 0 6px !important;
      text-align: left;
    }

    img {
      max-width: 100%;
      max-height: 24px;
      margin-right: 0.5rem;

      @include av-mq(handheld-and-up) {
        max-height: 30px;
        margin-right: 1rem;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      font-weight: bold;

      @include av-mq(handheld-and-up) {
        height: 30px;
        line-height: 30px;
        font-size: 30px;
      }
    }
  }

  &__partner-sub-logo {
    margin-bottom: 30px;
    padding: 0 1rem;
    text-align: center;

    @include av-mq(desk) {
      padding: 0 0 0 6px !important;
      text-align: left;
    }

    img {
      max-width: 100%;
      max-height: 14px;
    }

    .logo-x {
      margin: 0 7px 0 10px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      height: 16px;
      line-height: 16px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -1px;
    }
  }

  &__sub-title {
    margin-bottom: 0.5rem;
    font-size: 18px;
    line-height: 1.48;
    color: #323438;

    @include av-mq(desk) {
      margin-bottom: 1rem;
      font-size: 24px;
    }
  }

  &__title {
    padding: 0 1rem;
    font-size: 32px;
    line-height: 1.3;
    color: #323438;

    @include av-mq(desk) {
      margin-bottom: 40px;
      padding: 0;
      font-size: 48px;
    }
  }

  &__input {
    position: absolute;
    top: 574px;
    left: 50%;
    width: calc(100% - 2rem);
    max-width: 456px;
    padding: 0 1rem;
    transform: translateX(-50%);

    @include av-mq(desk) {
      position: static;
      padding: 0;
      width: 456px;
      transform: none;
    }
  }

  &__btns {
    position: absolute;
    bottom: 0;
    width: 100%;

    @include av-mq(desk) {
      position: static;
    }

    .btn {
      font-weight: bold;

      @include av-mq(portable) {
        float: left;
        width: 100%;
        padding: 1rem 0;
        border-radius: 0;
        font-size: 16px;
        border: 0;
      }

      @include av-mq(desk) {
        margin-right: 0.5rem;
        min-width: 180px;
      }

      &--partner {
        @include av-mq(desk) {
          margin-right: 0.5rem;
          width: 200px;
        }
      }
    }
  }

  &__video {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1024px;
    transform: translateX(-50%);
    z-index: 1;

    & > video {
      width: 100%;
      height: 100%;
    }

    @include av-mq(desk) {
      width: 1920px;
    }
  }
}
</style>
