<template>
  <div
    :class="{ 'main-summary--animation': startAnimation }"
    class="main-summary"
  >
    <div class="main-summary__title">
      <img src="~assets/images/main/main-summary-medal.png" alt="" />
    </div>

    <ul ref="animationElement" class="main-summary__text-list">
      <li class="main-summary__text-item">
        업계 평균 대비
        <strong>
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="90"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
          </client-only>
        </strong>
        점 높은 만족도
      </li>
      <li class="main-summary__text-item">
        추천 지원금 신청액 업체당
        <strong>
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="1400"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
          </client-only></strong
        >만 원
      </li>
      <li class="main-summary__text-item">
        찾아드린 절세 누락금
        <strong>
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="646"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
          </client-only>
        </strong>
        만 원
      </li>
    </ul>
    <div class="main-summary__bg">
      <img src="~assets/images/main/main-summary-bg.png" alt="" />
    </div>
    <div class="main-summary__date">
      2022년 상반기 기준
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MainSummary',
  data: () => ({
    animationStartPostion: 0,
    option: {
      useEasing: true,
      separator: ','
    }
  }),
  computed: {
    ...mapState(['scrollPositionY']),
    startAnimation() {
      return this.scrollPositionY.bottom > this.animationStartPostion
    }
  },
  mounted() {
    this.setAnimationPosition()
    window.addEventListener('resize', this.setAnimationPosition)
  },
  destroyed() {
    window.removeEventListener('resize', this.setAnimationPosition)
  },
  methods: {
    setAnimationPosition() {
      this.$nextTick(() => {
        this.animationStartPostion = this.offset(
          this.$refs.animationElement
        ).top
      })
    },
    offset(el) {
      const rect = el.getBoundingClientRect()
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
  }
}
</script>

<style lang="scss">
.main-summary {
  position: relative;
  padding: 3rem 1rem;
  text-align: center;

  @include av-mq(lap-and-up) {
    padding: 140px 1rem 70px;
    text-align: left;
  }

  &__title {
    margin-bottom: 2rem;

    @include av-mq(lap-and-up) {
      margin-bottom: 1rem;
    }
  }

  &__text-list {
    position: relative;
    margin-bottom: 2rem;
    font-size: 20px;
    line-height: 1.3;
    z-index: 2;

    @include av-mq(handheld-and-up) {
      font-size: 28px;
    }

    @include av-mq(lap-and-up) {
      margin-bottom: 2rem;
      font-size: 40px;
      line-height: 72px;
    }
  }

  &__text-item {
    margin-bottom: 0.5rem;
    transform: translateY(-10px);
    opacity: 0;
    transition: 0.2s all;
    word-break: keep-all;
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%) scale(0.3);
    transition: 0.3s all;
    z-index: 1;

    img {
      max-width: 100%;
    }
  }

  &__date {
    padding-left: 4px;
  }

  &--animation {
    .main-summary__text-item {
      animation-name: slidein;
      animation-duration: 0.7s;
      animation-fill-mode: both;

      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &:nth-child(4) {
        animation-delay: 0.8s;
      }
    }

    .main-summary__bg {
      animation-name: slidein2;
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-delay: 0.2s;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slidein2 {
  from {
    transform: translate(-50%, -50%) scale(0.3);
  }

  to {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
</style>
