<template>
  <div class="branch-card">
    <div class="branch-card__branchName">
      {{ data.fields.branchName }}
    </div>
    <div class="branch-card__photo">
      <img :src="data.fields.branchLogo.fields.file.url" alt="" />
    </div>
    <div class="branch-card__accountantName">
      {{ data.fields.accountantName }} 세무사
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.branch-card {
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;

  &__photo {
    margin-bottom: 0.75rem;
    img {
      height: 130px; /* Set the height you want */
      object-fit: contain; /* Maintain aspect ratio */
      width: 100%;
    }
  }

  &__accountantName {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
  }

  &__branchName {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: bold;
  }

  &__address {
    margin-bottom: 1rem;
    font-size: 12px;
    height: 52px;
    line-height: 1.3;
    word-break: break-word;
  }

  &__phoneNumber {
    height: 12px;
    font-size: 12px;
  }
}
</style>
