<template>
  <client-only>
    <div
      :class="{ 'main-pricing--fastfive': partnerContent.name === 'fastfive' }"
      class="main-pricing"
    >
      <div
        v-html="
          displayPartnerContent(
            '이 모든 서비스가<br> 월 8만원부터!',
            'benefitTitle'
          )
        "
        class="main-pricing__title"
      ></div>
      <div class="main-pricing__box">
        <div class="main-pricing__box-title">
          세무대행 서비스 <small>월 8만원 부터</small>
        </div>
        <ul class="main-pricing__feature">
          <li class="main-pricing__feature-item">세무 시스템 제공</li>
          <li class="main-pricing__feature-item">실시간 카톡/전화 상담 채널</li>
          <li class="main-pricing__feature-item">
            세무상담 및 장부 작성
          </li>
          <li class="main-pricing__feature-item">
            세무신고 대행
          </li>
          <li class="main-pricing__feature-item">
            급여, 원천세, 4대 보험 신고
          </li>
          <li class="main-pricing__feature-item">
            결산 및 세무조정 (연 1회 별도 과금)
          </li>
        </ul>

        <template v-if="partnerBenefit">
          <div
            v-html="partnerBenefit.title"
            class="main-pricing__box-title"
          ></div>
          <ul class="main-pricing__feature">
            <li
              v-for="(item, index) in partnerBenefit.list"
              :key="index"
              class="main-pricing__feature-item"
            >
              {{ item }}
            </li>
          </ul>
        </template>
        <div class="main-pricing__form">
          <btn-consultation custom-class="btn btn--primary btn--large" />
        </div>
      </div>
    </div>
  </client-only>
</template>

<script>
import { mapGetters } from 'vuex'
import BtnConsultation from '@/components/common/btn-consultation.vue'

export default {
  components: { BtnConsultation },
  computed: {
    ...mapGetters({
      partnerContent: 'getPartnerContent'
    }),
    partnerBenefit() {
      return this.partnerContent.partner_benefit
    }
  }
}
</script>

<style lang="scss">
.main-pricing {
  padding: 4.5rem 1rem 3rem;
  background: #ecf2fe url('/images/common/heum-pattern.png') repeat;
  word-break: keep-all;

  @include av-mq(lap-and-up) {
    padding: 6.5rem 1rem 4rem;
  }

  &--fastfive {
    background: #ecf2fe url('/images/common/fastfive-pattern.png') repeat;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 26px;
    line-height: 1.48;
    color: #323438;
    text-align: center;

    @include av-mq(lap-and-up) {
      margin-bottom: 3rem;
      font-size: 32px;
    }
  }

  &__box {
    max-width: 688px;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    @include av-mq(lap-and-up) {
      padding: 2rem 2rem 3rem;
    }
  }

  &__box-title {
    margin-bottom: 2rem;
    font-size: 22px;
    color: #323438;
    line-height: 1.48;

    @include av-mq(lap-and-up) {
      font-size: 28px;
    }
  }

  &__feature {
    margin-bottom: 2rem;
    overflow: hidden;
  }

  &__feature-item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.69;
    color: #323438;
    box-sizing: border-box;

    @include av-mq(lap-and-up) {
      float: left;
      width: 50%;
    }

    &:before {
      content: '';
      position: absolute;
      left: -2px;
      width: 30px;
      height: 30px;
      margin-right: 0.25rem;
      background-image: url('/images/main/ico-pricing.png');
      background-size: cover;
      vertical-align: middle;
    }
  }

  &__form {
    max-width: 456px;
    margin: 0 auto;
    text-align: center;

    .btn--fastfive {
      width: 200px;
    }

    &-description {
      text-align: left;
      font-size: 13px;
      line-height: 1.48;
      color: #85878c;

      a {
        color: #85878c;
      }
    }
  }
}
</style>
