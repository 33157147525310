<template>
  <div class="main-tax-avoidance">
    <div class="main-tax-avoidance__title">
      절세, 정말 제대로 챙기고 있나요?
    </div>
    <div class="main-tax-avoidance__subtitle">
      혜움은 세무 전문가가 IT기술을 활용하여<br />
      누락 여부를 조회해드립니다.
    </div>
    <div ref="animationElement" class="main-tax-avoidance__contents grid">
      <div class="main-tax-avoidance__item grid__cell col-1/3--lap-and-up">
        <div class="main-tax-avoidance__item-title">
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="53"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
            %
          </client-only>
        </div>
        <p class="main-tax-avoidance__item-text">
          고객의 53%가 누락된<br />
          절세 혜택을 받았습니다.
        </p>
      </div>
      <div class="main-tax-avoidance__item grid__cell col-1/3--lap-and-up">
        <div class="main-tax-avoidance__item-title">
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="1400"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
            만 원
          </client-only>
        </div>
        <p class="main-tax-avoidance__item-text">
          혜움 지원금 추천 시스템으로 평균 1,400만 원을 추가로 받았습니다.
        </p>
      </div>
      <div class="main-tax-avoidance__item grid__cell col-1/3--lap-and-up">
        <div class="main-tax-avoidance__item-title">
          <client-only>
            <iCountUp
              :delay="200"
              :endVal="646"
              :option="option"
              v-if="startAnimation"
            />
            <template v-else>0</template>
          </client-only>
          만 원
        </div>
        <p class="main-tax-avoidance__item-text">
          누락된 세제혜택 646만 원을 찾아드렸습니다.
        </p>
      </div>
    </div>
    <div class="main-tax-avoidance__date">2022년 상반기 기준</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MainTaxAvoidance',
  data: () => ({
    animationStartPostion: 0,
    option: {
      useEasing: true,
      separator: ','
    }
  }),
  computed: {
    ...mapState(['scrollPositionY']),
    startAnimation() {
      return this.scrollPositionY.bottom > this.animationStartPostion
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.animationStartPostion = this.$refs.animationElement.offsetTop
    })
  }
}
</script>

<style lang="scss">
.main-tax-avoidance {
  padding: 3rem 1rem;

  @include av-mq(lap-and-up) {
    padding: 5.5rem 1rem 3rem;
  }

  &__title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      margin-bottom: 1.5rem;
      font-size: 32px;
    }
  }

  &__subtitle {
    margin-bottom: 3rem;
    padding: 0 1rem;
    font-size: 18px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      font-size: 20px;
    }
  }

  &__item {
    margin-bottom: 40px !important;

    &:last-of-type {
      margin-bottom: 0 !important;
    }

    @include av-mq(lap-and-up) {
      margin-bottom: 0 !important;
    }
  }

  &__item-title {
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 1rem;
    font-size: 36px;
    font-weight: bold;
    color: #1f75e8;
    text-align: center;

    @include av-mq(lap-and-up) {
      font-size: 48px;
    }
  }

  &__item-text {
    max-width: 260px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.61;
    color: #323438;
    word-break: keep-all;
    text-align: center;

    @include av-mq(lap-and-up) {
      font-size: 18px;
      line-height: 1.61;
    }

    small {
      font-size: 14px;
    }
  }

  &__contents {
    margin-bottom: 4rem;
  }

  &__date {
    text-align: center;
    font-size: 15px;
  }

  &__link {
    padding-top: 56px;
    text-align: center;
    word-break: keep-all;
    line-height: 1.56;

    @include av-mq(lap-and-up) {
      padding-top: 104px;
    }

    a {
      font-size: 16px;
      color: #1f75e8;
      text-decoration: none;

      @include av-mq(lap-and-up) {
        font-size: 22px;
      }
    }
  }
}
</style>
