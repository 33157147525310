<template>
  <div class="main-tax-accountant">
    <div class="main-tax-accountant__title">
      200여명의 세무 전문가들이<br />
      대표님과 함께 합니다.
    </div>
    <div class="main-tax-accountant__subtitle">
      <ul>
        <li>- 세무기장, 경정청구, 조세불복 등 분야별 전문가</li>
        <li>- 서울, 경기 등 전국 40개 지점 운영</li>
        <li>- 자체 IT 연구소 운영, IT 전문가 50여 명</li>
      </ul>
    </div>

    <div class="main-tax-accountant__photo">
      <img
        v-show="!isMobile"
        src="~/assets/images/main/main-tax-accountant-v4-desktop.jpg"
        alt=""
      />
      <img
        v-show="isMobile"
        src="~/assets/images/main/main-tax-accountant-v4-desktop.jpg"
        alt=""
      />
    </div>
    <div class="main-tax-accountant__link">
      <nuxt-link to="/heum-people"
        >혜움만의 생각을 알아보세요 &#x2192;</nuxt-link
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      const mq = this.$mq
      return mq === 'thumb' || mq === 'pocket'
    }
  }
}
</script>

<style lang="scss">
.main-tax-accountant {
  padding: 4.5rem 0 3rem;
  overflow: hidden;

  @include av-mq(lap-and-up) {
    padding: 6.5rem 0 3rem;
  }

  &__title {
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      margin-bottom: 2rem;
      font-size: 32px;
    }
  }

  &__subtitle {
    margin-bottom: 2rem;
    padding: 0 1rem;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      margin-bottom: 3rem;
      font-size: 20px;
    }
  }

  &__photo {
    position: relative;
    margin-bottom: 0rem;
    text-align: center;
    overflow: hidden;
    //background: rgb(143, 141, 135);
    //background: linear-gradient(
    //  90deg,
    //  rgba(143, 141, 135, 1) 0%,
    //  rgba(157, 156, 151, 1) 50%,
    //  rgba(140, 137, 127, 1) 100%
    //);

    img {
      max-width: 100%;

      @include av-mq(lap-and-up) {
      }
    }
  }

  &__contents {
    max-width: 1024px;
    margin: 0 auto !important;
    padding: 0 2rem;
  }

  &__item {
    max-width: 300px;
    margin: 0 auto 3.5rem;

    @include av-mq(lap-and-up) {
      margin-bottom: 0 !important;
    }
  }

  &__item-title {
    position: relative;
    margin-bottom: 0.5rem;
    font-size: 18px;

    &:before {
      position: absolute;
      top: -1.5rem;
      left: 0;
      content: '';
      display: block;
      width: 24px;
      height: 3px;
      background-color: #1f75e8;
    }

    @include av-mq(lap-and-up) {
      margin-bottom: 1rem;
      font-size: 20px;
    }
  }

  &__item-text {
    padding-right: 1rem;
    font-size: 15px;
    line-height: 1.6;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      max-width: 200px;
    }

    li {
      position: relative;
      padding-left: 0.75rem;
      &:before {
        position: absolute;
        left: 0;
        display: block;
        content: '-';
      }
    }
  }

  &__link {
    padding-top: 4rem;
    text-align: center;

    a {
      font-size: 18px;
      color: #1f75e8;
      text-decoration: none;

      @include av-mq(lap-and-up) {
        font-size: 22px;
      }
    }
  }
}
</style>
