<template>
  <div class="main-customer">
    <div class="main-customer__title">
      평범한 후기 몇 개가 아닙니다<br />
      진정성 있는 후기 <strong>2,384</strong>개는 만들 수 없습니다
    </div>
    <div class="main-customer__caroucel">
      <client-only>
        <carousel
          :responsive="{
            0: { items: 1 },
            768: { items: 2 }
          }"
          :stagePadding="isMobile ? 46 : 64"
          :nav="false"
          :dots="false"
          :margin="isMobile ? 16 : 26"
        >
          <template slot="prev"><span class="owl-prev"></span></template>
          <voc-card
            v-for="(review, index) in CUSTOMER_REVIEWS"
            :key="index"
            :data="review"
          />
          <template slot="next"><span class="owl-next"></span></template>
        </carousel>
      </client-only>
    </div>
    <div class="main-customer__link">
      <nuxt-link to="/customer-review"
        >실제 고객후기를 더 살펴보세요 &#x2192;</nuxt-link
      >
    </div>
  </div>
</template>

<script>
import CUSTOMER_REVIEWS from '~/customer-reviews.json'
import VocCard from '~/components/customer-review/voc-card'

export default {
  components: {
    VocCard
  },
  computed: {
    CUSTOMER_REVIEWS() {
      return CUSTOMER_REVIEWS.sort((a, b) => {
        if (a.pros.length > b.pros.length) {
          return -1
        }
        if (a.pros.length < b.pros.length) {
          return 1
        }

        return 0
      }).slice(0, 50)
    },
    isMobile() {
      const mq = this.$mq
      return mq === 'thumb' || mq === 'pocket'
    }
  }
}
</script>

<style lang="scss">
.main-customer {
  padding: 4.5rem 0 3rem;
  overflow: hidden;

  @include av-mq(lap-and-up) {
    padding: 6.5rem 0 3rem;
  }

  &__title {
    padding: 0 1rem;
    margin-bottom: 4.5rem;
    font-size: 26px;
    line-height: 1.48;
    text-align: center;
    color: #323438;
    word-break: keep-all;

    @include av-mq(lap-and-up) {
      font-size: 32px;
    }
  }

  &__company-logos {
    padding: 0 1rem;
  }

  &__caroucel {
    position: relative;
    margin-bottom: 2rem;
  }

  &__link {
    text-align: center;

    a {
      font-size: 18px;
      color: #1f75e8;
      text-decoration: none;

      @include av-mq(lap-and-up) {
        font-size: 22px;
      }
    }
  }

  .owl-stage-outer {
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 32px;
      right: 0;
      width: 2rem;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .owl-stage {
    padding: 16px 32px 16px 16px !important;
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 76px;
    height: 76px;
    cursor: pointer;
    background-size: cover;

    @include av-mq(lap-and-up) {
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
      height: 100px;
    }
  }

  .owl-prev {
    left: 1rem;
    background-image: url('~assets/images/main/btn-prev.png');
  }

  .owl-next {
    right: 1rem;
    background-image: url('~assets/images/main/btn-next.png');
  }
}
</style>
